@import './global';
@import './mixin';


#ARMADOR_Banner_01 {
    
    .slick-slider {
        -webkit-tap-highlight-color:unset;
        padding-left: 0;
        padding-right: 0;

        .slick-prev {
            
            left: 20px;
            
            &::before {
                content: "\f104";
            }

        }

        .slick-next {

            right: 20px;
            
            &::before {
                content: "\f105";
            }

        }

        .slick-prev, .slick-next {

            z-index: 1;
            width: unset;
            height: unset;
            
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: $black-color;
                font-size: 40px;
                
                @media (max-width: 600px) {
                    font-size: 30px;
                }
            }

        }


        .slick-arrow { 
            
            opacity: 0; 
            transition: all .25s ease-in-out; 
            -moz-transition: all .25s ease-in-out; 
            -webkit-transition: all .25s ease-in-out; 

            @media (max-width: 600px) {
                width: 30px !important;
                height: 30px !important;
            }

            width: 50px;
            border-radius: 50%;
            height: 50px;
            background-color: white;
        
        } 
        
        &:hover{ 

            .slick-arrow { 
                opacity: 1; 
            }

        }
    }

    .slick-list .slick-slide .armador-banner-item {
        height: calc(850px - 52px);
        img {
            object-fit: cover;
            min-height: calc(850px - 52px);
        }
    }

    .armador-banner-item {
        position: relative;
    }

    .armador-carousel-caption {
        position: absolute;

        right: 0;
        left: 0;
        bottom: 3%;
        padding: 0;
    }

    .slick-dots {
        bottom: 5%;

        li {
            background-color: $white-color;
        }

        li.slick-active {
            background-color: $black-color;
        }
    }

    .carousel-caption-inner {
        text-align: left;
        max-width: 690px;
        margin-right: -140px;
        position: relative;
        z-index: 1;

        h5 {
            position: relative;

            &::after {
                content: "";
                height: 2px;
                width: 36px;
                background-color: $skin-color;
                position: absolute;
                top: 12px;
                margin-left: 1em;
            }
        }

        &.banner2 {
            float: right;
        }
        
        &.banner3 {
            text-align: center; color:$white-color;
            max-width: 860px;margin: 0 auto;
            
            h2 {
                color:$white-color;
                &::before{content: none;}
            }
            
            p {
                color: rgba($white-color, .7);
                max-width: 700px;
                margin:0 auto 10px;
            }
            
            span {
                height: 37px;
                width: 2px;
                background-color: $white-color;
                position: absolute;
            }
        }
        
        &.banner4 {

            text-align: left;float: none;
            
            h2 {
                color:$white-color;
                &::before{content: none;}
            }
            
            p {
                color: rgba($white-color, .7);
            }

        }
        
        h2 {
            
            line-height: 40px !important;
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 62px;
            
            @media (max-width: 600px) {
                @apply tw-line-clamp-2;
            }
        }
        
        p {

            line-height: 27px;
            letter-spacing: 0px;
            font-weight: 400;
            font-size: 16px;
            color: #3b404c;
            @media (max-width: 600px) {
                @apply tw-line-clamp-3;
            }
            
        }

        button {

            font-size:15px;
            padding: 15px 35px;
            margin-top: 20px;
            
            i {
                font-size: 22px;
                vertical-align: middle;
            }
            
            &.btn-inline {
                padding: 15px 25px;
            }
        }
    }

    .slick-slide {

        .container-xl{
            position: relative;
            align-items: center;
        }

    }
    
    .effect {
        img {
            max-height: 600px;
            min-height: auto !important;
        }

        span {
            content:"";
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $skin-color;
            border-radius: 50%;
            top: 70px;
            left: 70px;
            &:nth-child(1){background-color: $skin-color;}
            &:nth-child(2){
                background-color: #b6bfd5;
                right: 80px;
                left: auto;
                top: 130px;
                width: 35px;
                height: 35px;
            }
            &:nth-child(3){
                background-color: #263045;
                width: 30px;
                height: 30px;
                top: auto;
                bottom: 100px;
                right: 120px;
                left: auto;
            }
            &:nth-child(4){
                background-color: #b6bfd5;
                width: 25px;
                height: 25px;
                top: auto;
                bottom: 50px;
                // right: 120px;
                left: 50px;
            }
            // &:nth-child(4){
            //     color: #fff;
            //     width: 20px;
            //     height: 20px;
            //     top: auto;
            //     bottom: 0;
            //     left: -20px;
            //     font-size: 15px;
            //     padding: 30px;
            //     svg{font-size: 37px;display: block;margin: 0 auto;}
            // }
        }
    }

}

@media only screen and (max-width: 1199px) {

    #ARMADOR_Banner_01 {
        .carousel-caption-inner {
            h2 {
                font-size: 50px;
                line-height: 55px;
            }
        }

        .slick-slide {

            .container-xl{
                justify-content: space-between;
            }
    
        }

        .armador-carousel-caption {
            bottom:0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;            
            
            img {
                max-height: 400px;
            }
        }
        
        .slick-list .slick-slide .armador-banner-item {
            height: auto;

            img {
                min-height:auto
            }
        }
    }

}

@media (max-width: 991px) {

    #ARMADOR_Banner_01{
        .carousel-caption-inner {
            h2{
                font-size: 35px;
                line-height: 40px;
            }
            p { 
                font-size: 14px;
                line-height: 20px;
            }
        
        }

        .slick-list .slick-slide .armador-banner-item {
            height: auto;
            
            img {
                min-height: auto;
            }
        }

        .armador-carousel-caption {
            padding: 30px 0 0;
            
            .effect {
                span { 
                    display: none;
                }

                img {
                    max-height: 400px;
                }
            }
        }
    }

}

@media (max-width: 767px) {

    #ARMADOR_Banner_01 {
        .carousel-caption-inner {
            text-align: center !important;
            margin: 0 auto;
            
            h2 {
                font-size: 30px;
                line-height: 35px;
                
                &::before {
                    content: none;
                }
            }
            
            h5::after {
                content:none;
            }

            p.text {
                display: none;
            }
            
            button {
                font-size: 13px; 
                padding: 10px 20px;
            }
            
            &.banner2 {
                float: none;
            }
            
            &.banner3 span { 
                display: none;
            }
        }
        
        .armador-carousel-caption {
            padding: 0;bottom: 0;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 100%;
        }

        .effect {
            display: none !important;
        }
        
        .slick-list .slick-slide .armador-banner-item img {
            min-height: 300px;
        }

        .slick-dots {
            bottom: 0;
        }
    }

}
