@tailwind components;
@tailwind utilities;

.preloader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    @apply tw-w-full;
    @apply tw-h-full;

    @apply tw-bg-white;

    z-index: 200;

    svg {
        path {
            @apply tw-fill-black;
        }
    }

    img {
        @apply tw-w-20;
        @apply tw-h-20;
    }
}

.loader-circle {
    position: absolute;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
}

.loader-line-mask {
    position: absolute;
    width: 128px;
    height: 128px;
    overflow: hidden;
    transform-origin: 64px 64px;
    -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    animation: rotate 1.2s infinite linear;

    .loader-line {
        width: 128px;
        height: 128px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .5);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}