@import './global';
@import './mixin';
/** 
      1. ttm-row 

      2. Row-Equal-Height

      3. Bg-Layer 

      4. Row-Bg-Image

      5. Col-Bg-Image

      6. Section-Title 

      7. Separator

      8. Buttons

      9. Icons

      10. Fid

      11. featured-icon-box

      12. Featured-imagebox

      13. List box
      
      14. Processbox

      15. Progress-Bar 

      16. Testimonial

      17. Client-row

      18. Accordion

      19. Tab

      20. Boxes-Spacing

      21. Pricing-Plan

      22. Sidebar

      23. products

      24. History box 

      25. Slick slider
      

**/


/* ===============================================
    1.ttm-row
------------------------*/
.ttm-row {
    padding: 90px 0;
    &.zero-padding-section {padding: 0;}
    &.service-section {padding: 0!important;}
    &.history-section {padding-bottom: 170px;}
    &.team-section {padding-top: 180px;}
    &.first-row-title-section {padding-top: 120px; padding-bottom: 190px;}
    &.bottomzero-padding-section {padding-bottom: 0;}
    &.services-section {padding-bottom: 165px;padding-top: 180px;}
    &.second-row-title-section {padding-top: 180px;}
    &.our-partner-section {padding-bottom: 20px; padding-top: 120px; }
    &.fid-section { padding-top: 315px; }
    &.third-row-title-section { padding-top: 320px; }
    &.our-client-section{padding: 21px 0;}
    &.aboutus-section-style3{padding-bottom: 230px;}
    &.grid-section{padding-bottom: 60px;}
    &.map-section {padding: 0px;}
    &.services-slide-section{padding-bottom: 320px;}
    &.our-partner-section { padding-bottom: 20px;  padding-top: 120px; }
}


/* ===============================================
    2.Row-Equal-Height
------------------------*/
.row-equal-height{display:flex;}
.row-equal-height > [class*='col-'] {
    display: flex;
    flex-wrap: wrap;
}

/* ===============================================
    3.Bg-Layer 
------------------------*/
.ttm-bg{ 
    position: relative;
    &.ttm-bgcolor-grey:not(.ttm-col-bgcolor-yes) .ttm-bg-layer {
        opacity: 0.7;
    }
    &.ttm-bgcolor-darkgrey .ttm-bg-layer {
        opacity: 0.8;
    }
}
.ttm-col-bgimage-yes, .bg-layer-equal-height .ttm-col-bgcolor-yes{ height: 100%; z-index: 9;}
.ttm-bg-layer , .ttm-titlebar-wrapper .ttm-titlebar-wrapper-bg-layer,
.ttm-bg-layer .ttm-bg-layer-inner {
    position: absolute;
    height: 100%;
    width: 100%; 
    top: 0;
    left: 0;
}
.ttm-bg-layer, .ttm-bg-layer .ttm-bg-layer-inner {
    background-color: inherit;
    opacity: .7;
}
.ttm-equal-height-image {
    display: none;
}

/* ===============================================
    4.Row-Bg-Image  
------------------------*/
.bg-img1 {
}
.bg-img2 {
}
.bg-img3 {
}
.bg-img4 {
}
.bg-img5 {
}
.bg-img6 {
    .ttm-bg-layer, .ttm-bg-layer-inner {
        margin-left: -400px;
        opacity: 1;
    }
}
.bg-img7 {
}
.bg-img8 {
}
.bg-img9 {
}
.bg-img10 {
}
.bg-img11 {
}
.bg-img12 {
}


/* ===============================================
    5.Col-Bg-Image  
------------------------*/
.ttm-equal-height-image{ display: none; }
.col-bg-img-one.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
    opacity: 1; 
}
.col-bg-img-two.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
}
.col-bg-img-three.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
}
.col-bg-img-four {
}
.col-bg-img-five.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
}
.col-bg-img-six.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
}
.col-bg-img-one.ttm-left-span {
    margin-left: -500px;
    margin-right: -570px;
}
.ttm-bgcolor-white.ttm-left-span .ttm-col-wrapper-bg-layer {margin-left: -500px;}
.ttm-left-span.spacing-7 .ttm-col-wrapper-bg-layer{margin-left: -500px; opacity: 1;}
.ttm-right-span {margin-right: -400px;}
// .ttm-bgcolor-white.ttm-right-span {margin-right: -400px;}

/* ===============================================
    6.Section-Title  
------------------------*/
.section-title { 
    position: relative;
    &.with-desc.text-center .title-header:before {
        left: 50%;
        margin-left: -26px;
    }
    &.with-desc .title-header {
        margin-bottom: 52px;
    }
    h5{
        font-weight: 500;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 5px;
        color: $skin-color;
    }
    h2.title {
        @apply tw-text-3xl;
        // font-size: 34px;
        // line-height: 44px;
        margin-bottom: 0;
        span {
            font-style: italic;  
            font-weight: 700;
            letter-spacing: 1.2px;
            color: $skin-color;
        }
    }
    .title-desc{
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 15px;
    }
    .title-header{ 
        margin-bottom: 40px;
        position: relative;
        &:before{
            width: 52px;
            bottom: -20px;
            height: 4px;
            left: 0;
            top: auto;
            position: absolute;
            content: '';
            border: 4px solid transparent;
            border-bottom: 0;
            border-top-color: $skin-color;
        }        
    }
    &.style2, &.style3 {
         .title-header {
            &::before {
                content: none;
            }
            &::after {
                content: "";
                right: 25px;
                height: 90%;
                position: absolute;
                top: 10%;
                width: 1px;
                border-right-width: 1px;
                background-color: #f0f0f0;
            }
        }
    }
    &.style3 {
        .title-header {
            &::after {
                background-color: rgba($white-color, .09);
            }
        }
    }
}
.row-title h2.title {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 0px;
    padding: 0;
    text-transform: capitalize;
}
/* ===============================================
    7.separator
------------------------*/

.separator .sep-line {
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    width: 100%;
}
.ttm-bgcolor-skincolor, .ttm-bgcolor-darkgrey {
    .separator .sep-line {border-top-color: rgba($white-color, .07);}
}
/* ===============================================
    8.Buttons
------------------------*/
.ttm-btn{ 
    display: inline-block;
    line-height: normal;
    border: 1px solid transparent;
    position: relative;
    font-weight: 600;
    overflow: hidden;
    z-index: 2;
    vertical-align: middle;
    text-transform: capitalize;
    transition: all .2s ease-in-out;
    &:hover {cursor: pointer;}
    i, svg {
        margin-right: 10px;
    }
    &.ttm-icon-btn-right {
        i, svg {margin-right: 0; margin-left: 10px;}
        text-decoration: none;
    }
    &.ttm-bgcolor-darkgrey {
        color: $white-color;
        border-color: $darkgrey-color;
        &:hover {background-color: $skin-color;color: $white-color;border-color: $skin-color}
    }
    &.ttm-bgcolor-skincolor {
        color: $white-color;
        border-color: $skin-color;
        &:hover {background-color: $darkgrey-color;color: $white-color;border-color: $darkgrey-color;}
    }
    &.ttm-bgcolor-grey {
        &:hover {background-color: $skin-color; color:$white-color}
    }
    
    &.ttm-btn-style-border {
        border: 1px solid transparent;
        &.ttm-btn-color-darkgrey {
            border-color: $darkgrey-color;
            background-color: transparent;
            color: $darkgrey-color;
            &:hover {
                background-color: $darkgrey-color;
                color: $white-color;
            }
        }
        &.ttm-btn-color-skincolor {
            border-color: $skin-color;
            background-color: transparent;
            color: $skin-color;
            &:hover {
                background-color: $skin-color;
                color: $white-color;
            }
        }
        &.ttm-btn-color-white {
            border-color: $white-color;
            color: $white-color;
            background-color: transparent;
            &:hover {
                background-color: $white-color;
                color: $darkgrey-color;
            }
        }        
    }
    &.rounded-circle {border-radius: 2em!important;}
    &.ttm-btn-size-xs {
        @extend .ttm-btn;
        font-size: 12px; 
        padding: 7px 22px;
    }
    &.ttm-btn-size-sm {
        @extend .ttm-btn;
        font-size: 14px; 
        padding: 10px 24px;
    }
    &.ttm-btn-size-md {
        @extend .ttm-btn;
        font-size: 14px; 
        padding: 10px 30px;
    }
    &.ttm-btn-size-lg {
        @extend .ttm-btn;
        font-size: 20px; 
        padding: 18px 45px;
    }
}
.btn-inline {
    font-size: 14px;
    padding: 0 !important;
    border: 1px solid transparent;
    background-color: transparent;
    color: $darkgrey-color;
    overflow: visible;
    &.ttm-btn-color-skincolor{
        color: $skin-color;
        &:hover{color: $darkgrey-color;}
    }
    &.ttm-btn-color-darkgrey{
        color: $darkgrey-color;
        &:hover{color: $skin-color;}
    }
    i, svg {font-size: 12px;}
    &.style2{
        font-weight: 400;
        font-size: 15px;
        &::before{
            position: absolute;
            content: '';
            background: $white-color;
            height: 2px;
            width: 15px;
            left: 0;
            top: 11px;
            transform: scaleX(0);
            opacity: 0;
            transition: .5s ease;
        }
        &:hover{
            padding-left: 25px;
            transition: all .4s ease-in-out;
            &::before{
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }
}



/* ===============================================
    9.Icons
------------------------*/
.ttm-icon {
    position: relative;
    margin-bottom: 35px;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
    position: relative;
    transition: all .4s ease-in-out;
    i {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    img {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &.ttm-icon_element-size-xs {
        @include icon-box(42px);
        i, svg {font-size:16px;}
        img {height:16px; width:16px;}
        svg {
            @include icon-box(42px);
            width:16px;
        }
    }
    &.ttm-icon_element-size-sm {
        @include icon-box(52px);
        i, svg {font-size:28px;}
        img {height:28px; width:28px;}
        svg {
            @include icon-box(52px);
            width:28px;
        }
    }
    &.ttm-icon_element-size-md {
        @include icon-box(58px);
        i {font-size:30px;}
        img {height:30px; width:30px;}
        svg {
            @include icon-box(58px);
            width: 30px;
        }
    }
    &.ttm-icon_element-size-lg {
        @include icon-box(64px);
        i {font-size:45px;}
        img {height:45px; width:45px;}
        svg {
            @include icon-box(64px);
            width:45px;
        }
    }
    &.ttm-bgcolor-white {
        color: $skin-color;
        border-color: transparent;
    }
    &.ttm-icon_element-border {
        &.ttm-textcolor-skincolor {
            border-color: $darkgrey-color;
        }
    }
}
/** icon-size **/

/* play-btn / play-icon */
.ttm-play-icon-btn{display: inline-block;}
.ttm-play-icon {
    color: $white-color;
    display: block;
    position: relative;
    .ttm-icon.ttm-icon_element-size-md {
        @include icon-box(95px);
        margin:0;
    }
    .ttm-play-icon-animation{ 
        display: inline-block;
        position: relative;
        &::after, &::before {
            content: '';
            border: 1px solid;
            border-color: inherit;
            width: 150%;
            height: 150%;
            -webkit-border-radius: 100%;
            border-radius: 100%;
            position: absolute;
            left: -25%;
            top: -25%;
            opacity: 1;
            animation: 1s videoplay-anim linear infinite;
        }
        &::after {border-color: #bfbfbf;}
        &::before {border-color: #e4e4e4; animation-delay: .5s;}
    }
}
.ttm-play-icon-btn {
    .ttm-icon.ttm-icon_element-size-sm{
        display: block;
        @include icon-box(64px);
        line-height: 60px;
        margin: 0;
        z-index: 2;
        i, svg{ 
            padding-left: 5px;
            font-size: 25px;
            display: inline-block;
            vertical-align: middle;
            line-height: 0;
        }
    }
    .ttm-play-icon-animation{
        position: relative;
        display: inline-block;
        .ttm-icon{ margin-bottom: 0; }
        &::after, &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
        }
        &::after {
            z-index: 1;
            width: 95px;
            height: 95px;
            opacity: .1;
            background-color: $white-color;
        }
        &::before {
            width: 80px;
            height: 80px;
            opacity: .2;
            background-color: $white-color;
        }
    }
    &:hover {
        .ttm-play-icon-animation {
            &::after, &::before {
                animation:scaling 1.05s infinite;
            }
        }
    }
    &.ttm-play-icon-btn-center{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: -50px;
    }
}
@keyframes scaling{ 100% { width:200%;height:200%;opacity:0 } }


/* ===============================================
    10.Fid
------------------------*/
.inside {
    text-align: center;
    position: relative;
    padding: 29px 15px 25px 15px;
    h3 {
        margin-bottom: 0;
        bottom: -11px;
        width: 100%;
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 0;
        display: inline-block;
    }
    h4 {
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 37px;
        line-height: 40px;
    }
    &.ttm-fid-view {
        &-righticon, &-lefticon {
            h4 { margin-bottom: 0; float: left;}
            .ttm-fid-left {
                position: relative;
                display: block;
                width: 100%;
            }
            .ttm-fid-icon-wrapper {
                position: absolute;
                top: 0;
                i { font-size: 48px; }
            }
            .ttm-fid-contents {
                clear: both;
                overflow: hidden;
            }
        }
    }
    &.ttm-fid-view-lefticon .ttm-fid-contents { padding-left: 60px; }
}
/*  topicon */
.ttm-fid-view-topicon {
    .ttm-fid-icon-wrapper .ttm-icon{
        margin-bottom: 0;
        display: inline-block;
        position: relative;
        &.ttm-icon_element-size-lg {
            margin-bottom: 10px;
            @include icon-box(90px);
            border-radius: 5px;
            i{font-size: 40px;}
        }
    }
    h4{font-size: 46px;line-height: 56px;}
    h3{font-weight: 500;font-size: 16px;padding-top: 10px;}
}
/*  style1 and style2 */
.row > [class*='col-']:not(:last-child) >  .ttm-fid-view-lefticon.style1:before{
    position: absolute;
    content: '';
    background-color: rgba(243,243,243,.06);
    width: 1px;
    height: 100%;
    bottom: 0;
    right: 0px;
}
.ttm-fid-view-lefticon {
    &.style1{margin-bottom: 0; padding:0 0;margin-top: 35px;}
    .ttm-fid-icon-wrapper{margin-top: 20px;}
    &.style2{margin-bottom: 0; padding:30px 15px;}
}
.ttm-highlight-fid {
    &-style-1, &-style-2 {
        position: absolute;
        background: $white-color;
        padding: 24px 25px 19px;
        border: 3px solid #e8e9ea;
        max-width: 250px;
        top: 0;
        left: 0;
        z-index: 9;
        margin-bottom: 0;
        .inside{margin-bottom: 0;padding: 0;}
    }
    &-style-1 {
        .ttm-fid.inside {
            h3 {
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                color: #1e2637;
                display: inline;
            }
            h4 {
                font-size: 80px;
                line-height: 72px;
                padding-right: 10px;
                float: left;
                display: block;
            }
        }
        &::before{
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            z-index: 1;
            bottom: 11px;
            left: 113px;
            border: 10px solid transparent;
            border-bottom: 0;
            border-top-color: $white-color;
            margin-bottom: -19px;
        }
        &::after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            top: 118px;
            left: 110px;
            border: 13px solid transparent;
            border-bottom: 0;
            border-top-color: #e8e9ea;
        }
    }
    &-style-2{
        position: absolute;
        background: #f7f9fe;
        padding: 25px 25px 25px;
        max-width: 160px;
        bottom: -60px;
        right: 5px;
        left: auto;
        top: auto;
        border: 1px solid #dfe5f3;
        text-align: center;
        &::before{
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            z-index: 1;
            top: -13px;
            left: 30px;
            border: 13px solid transparent;
            border-top: 0;
            border-bottom-color: #f7f9fe;
        }
        &::after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            top: -17px;
            left: 27px;
            border: 16px solid transparent;
            border-top: 0;
            border-bottom-color: #e8e9ea;
        }
        .inside {
            h4{ font-size: 46px;line-height: 56px;color: $skin-color; }
            h3{color: #6e6e6e}
        }
    }
}


/* ===============================================
    11. featured-icon-box
------------------------*/
.featured-title h5{
    @apply tw-text-base;
    // font-size: 19px; 
    // line-height: 26px; 
    margin-bottom: 0px;
    a {
        color: inherit;
        text-decoration: none;
    }
}
.featured-desc p{margin-bottom: 0;}
.featured-icon-box {
    position: relative;    
    &.left-icon {
        .ttm-icon {margin-bottom: 0;}
        .featured-icon, .featured-content {
            display: table-cell;
            vertical-align: top;
        }
        .featured-content {
            padding-left: 24px;
        }
        &.icon-align-top .featured-icon{ vertical-align: top; padding-top: 5px; }
    }
    &.top-icon {
        .featured-content {
            padding-top: 14px;
        }
    }
    &.iconalign-before-heading {
        .featured-content{ display: table; }
        .ttm-icon {
            height: auto; 
            width: auto;
            &.style1{
                width: 50px; 
                height: 60px;
                margin-bottom: 0;
                .ttm-num:before{font-size: 20px;font-weight: 600; color: $darkgrey-color;}
            }
        }
        .featured-title{ display: table-cell; vertical-align: middle; padding-left: 20px;}
        .featured-desc{padding-top: 10px;}
    }
}
body{ counter-reset: section; }
.ttm-num:before{ 
    counter-increment: section; 
    content: counter(section, decimal-leading-zero) " " ;
    font-size: 14px;
} 
.featured-icon-box {
    &.without-icon {
        .featured-title h5 {
            padding-left: 26px;position: relative;
            &::before {
                content: "";
                position: absolute;
                width: 18px;
                height: 1px;
                margin: auto;
                top: 50%;
                left: 0;
                background-color: $darkgrey-color;
            }
        }
    }
    &.style1 {
        padding: 28px 20px;
    }
    &-style1-row {
        padding: 0 10px;
        margin-top: -35px;
        position: relative;
    }
    &.style2 {
        .ttm-icon.ttm-icon_element-size-md {
            svg, i{font-size: 24px;}
        }
    }
    &.style3 {
        padding-top: 15px;
        .ttm-icon.ttm-icon_element-size-lg {
            @include icon-box(47px);
        }
        .featured-content {
            padding-left: 12px;
        }
    }
    &.style4 {
        .ttm-icon.ttm-icon_element-size-md {
            @include icon-box(37px);
            i {font-size: 35px}
        }
    }
    &.style5 {
        position: relative;
        z-index: 1;
        overflow: hidden;
        border:1px solid rgba($white-color,0.15);
        padding: 40px 35px;
        border-radius: 5px;
        .ttm-icon.ttm-icon_element-size-lg{@include icon-box(45px);margin-bottom: 0;}
        .featured-content{padding-top: 10px;}
        .featured-title h5{margin-bottom: 10px;}
        &::before{
            position: absolute;
            content: '';
            background-color: $skin-color;
            bottom: 100%;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            transition: .55s cubic-bezier(.68,1.55,.265,.55);
        }
        &:hover:before{
            bottom: 0;
        }
    }
    &.style6 {
        border: 1px solid #ebeef0;
        padding: 30px 30px 30px 30px;
        height: 100%;
        position: relative;
        background-color: $white-color;
        transition: all 0.5s ease-in-out 0s;
        margin-right: -1px;
        .ttm-icon.ttm-icon_element-size-lg {
            margin-bottom: 10px;
            transition: all 0s;
            @include icon-box(50px);
            i{font-size: 50px;color: $skin-color;}
            svg{font-size: 50px;color: $skin-color; fill: $skin-color;}
            img{width: 50px; height: 50px;color: $skin-color;}
        }
        .featured-title h5{margin-bottom: 10px;}
        .featured-desc p{margin-bottom: 25px;}
        &:hover {
            background-color: $skin-color;
            color: $white-color;
            h5, a, i, svg {color: $white-color!important; fill: $white-color!important;}
        }
        .btn-inline.style2 {
            color: $skin-color;
            &:hover{padding-left: 25px !important;color: $white-color; transition: all .4s ease-in-out;}
        }
    }
    &.style7 {
        padding: 30px 20px;
        border:1px solid #ebedf2 !important;
        .ttm-icon.ttm-icon_element-size-md {
            @include icon-box(70px);
            i{font-size: 58px;}
        }
        .featured-title h5{margin-bottom: 5px;}
    }
    &.style8 {
        padding: 60px 30px 40px;
        background-color: $white-color;
        position: relative;
        .ttm-icon{
            @include icon-box(70px);
            position: absolute;
            top: -32px;
            left: 50%;
            margin-left: -33px;
            box-shadow: 0 0 12px 0 rgba(32,46,60,.06);
            text-align: center;
            background-color: $white-color;
        }
    }
}

/* ===============================================
    12. featured-imagebox
------------------------*/
.featured-imagebox-team {
    position: relative;
    overflow: hidden;
    .featured-thumbnail {
        position: relative;
        overflow: hidden;
        img{transition: all .5s;}
        .social-icons {
            position: absolute;
            top: 0;
            left: -40px;
            right: auto;
            padding: 10px;
            margin-top: 0;
            opacity: 0;
            z-index: 9;
            background-color: $skin-color;
            transition: .5s all ease;
            li {
                display: block;
                a {
                    font-size: 14px;
                    color: $white-color;
                    position: relative;
                }
            }
        }
    }
    &:hover{
        .featured-thumbnail img{transform: scale(1.05);}
    }
    &.style1 {
        .featured-title h5 a:hover{color:$skin-color}
        .featured-content {
            text-align: center;
            position: relative;
            z-index: 2;
            margin: -25px 40px 5px;
            padding: 18px 0 22px;
            background-color: $white-color;
            border-bottom: 5px solid transparent;
            transition: .5s cubic-bezier(.3,.57,.55,1);
            .category {
                color: #9dabaf;
                font-size: 15px;
                margin-bottom: 0;
            }
        }
    }
    &:hover {
        .featured-content {
            border-color: $skin-color;
        }
        .social-icons {
            opacity: 1;
            left: 0;
        }
    }
    &.style2 {
        .ttm-box-view-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            text-align: center;
            opacity: 0;
            transition: opacity .5s;
            background: linear-gradient(to bottom,rgba(4,62,76,0) 0%,rgba(38,48,69,.9) 100%);
            transition-property: top,opacity;
        }
        .featured-content{
            position: absolute;
            transition-duration: 0.5s;
            transform: translateY(130px);
            color: $white-color;
            text-align: center;
            top: 0;
            left: 0;
            right: 0;
        }
        .featured-title h5 a{color: $white-color;}
        &:hover {
            .featured-content{top: 50px;}
            .ttm-box-view-overlay{opacity: 1;}
        }
    }
}
/*  Portfolio  */
.featured-imagebox-portfolio {
    position: relative;
    overflow: hidden;
    .featured-thumbnail{
        position: relative;
        transition-timing-function: linear;
        transition-duration: .3s;
        transition-property: transform,box-shadow;
    }
    &.style1:hover {
        .featured-thumbnail {
            transform: translateY(-20px);
        }
    }
    .ttm-box-view-overlay {
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: 1;
        transition: all 0.5s;
        width: 100%;
        height: 100%;
        background-color: rgba($white-color,.75);
    }    
    .ttm-media-link a{
        display: inline-block;
        font-size: 17px;
        position: absolute;
        top: 42%;
        right: 50%;
        margin-top: -17px;
        @include icon-box(45px);
        border: 1px solid $skin-color;
        border-radius: 0;
        text-align: center;
        margin-right: 3px;
        transform: translateX(-20px);
        transition: .5s;
        background-color: $skin-color;
        color: $white-color;
        &:hover {background-color: transparent;color: $skin-color;}
        &.ttm_link {
            transform: translateX(20px);
            transition: 1s;
            margin-right: -51px;
        }
    }    
    .featured-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        padding: 15px;
        transform: translateY(100%);
        z-index: 2;
        transition: all 0.5s;
        text-align: center;
        background-color: $darkgrey-color;
        .featured-title h5 a {color: $white-color; }
    }
    .category p{margin-bottom: 0;color: $white-color;}
    &:hover {
        .ttm-box-view-overlay{ opacity: 1;}
        .ttm-media-link a {transform: translate(0);}
        .featured-content {transform: translateY(0%);}
    }
    &.style2 {
        .featured-thumbnail {
            &::before {
                position: absolute;
                content: '';
                background-color: rgba($white-color, .75);
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: .9s ease;
                z-index: 1;
                opacity: 0;
            }
        }        
        .featured-content {
            position: absolute;
            bottom: 30px;
            left: 30px;
            opacity: 0;
            width: 84%;
            z-index: 1;
            transform: translateY(100%);
            transition: all 0.9s;
            padding: 23px 25px 24px;
            text-align: left;
            background-color: $skin-color;
        }
        &:hover {
            .featured-thumbnail:before{opacity: 1;}
            .featured-content{
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
} 

/*  blog  */
.featured-imagebox-blog{
    background-color: $white-color;
    margin-bottom: 10px;
    box-shadow: 0 0 12px 0 rgba(32,46,60,.06);
    .featured-thumbnail {
        position: relative;
        overflow: hidden;
        img {
            transform: scale(1.075);
            transition: all 3s ease-out;
            transition-delay: 0.15s;
        }
    }    
    .featured-content{
        padding: 45px 25px 25px 25px; position: relative;
        a:hover{color: $skin-color;}
    }
    .post-meta{
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        i{padding-right: 5px;}
        .ttm-meta-line{
            position: relative;
            &:not(:last-child){ 
                padding-right: 23px;
                &::after{
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 1px;
                    right: 10px;
                    top: 0;
                    background-color: #dce1e9;
                }
            }
            i, svg {color: $skin-color; margin-right: 5px;}
        }
    }
    .ttm-box-post-date{
        left: 25px;
        top: -35px;
        border-radius: unset;
    }
    &:hover {
        box-shadow: 1px 2px 10px rgba(0,0,0,.10);
        .ttm-blog-overlay-iconbox a {
            opacity: 1;
            transform: scale(1);
        }
        .ttm-box-view-overlay {
            &::before, &::after {
                opacity: .6;
                transform: translateY(0);
                background-color: $darkgrey-color;
            }
        }
    }
    &.style2{
        padding: 15px;
        .featured-content{ padding: 25px 5px 5px;}
        .ttm-box-post-date {
            left: 15px;
            top: -80px;
            .entry-date{display: block;line-height: 30px;}
        }
        .ttm-entry-date span {
            display: block;
            background-color: $white-color;
            color: $black-color;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
.ttm-blog-overlay-iconbox a {
    opacity: 0;
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -17px;
    display: inline-block;
    height: 25px;
    width: 25px;
    line-height: 25px;
    color: $white-color;
    font-size: 25px;
    background: transparent;
    transition: all .9s ease;
    transform: scale(0);
}
.post-meta{
    display: block;
    padding: 5px 0;
    margin: 10px 0;
}
.ttm-box-post-date{
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    color: $white-color;
    .entry-date{
        height: 60px;
        width: 60px;
        display: block;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        background-color: $skin-color;
    }
}
.ttm-entry-date span{display: block;}
.featured-imagebox-blog .ttm-box-view-overlay {
    &::after, &::before {
        content: '';
        position: absolute;
        left: 0!important;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all .5s ease;
        transform: translateY(-100%);
    }
    &::before {
        right: 0!important;
        left: auto;
        transform: translateY(100%);
    }
}
/*  services  */
.featured-imagebox-services {
    .featured-title h5{margin-bottom: 10px;}
    &.style1 {
        .featured-content{
            padding: 34px 50px 30px;
            margin: -55px 20px 0;
            text-align: center;
            border-bottom: 3px solid $skin-color;
            position: relative;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            background-color: $white-color;
        }
        &:hover {
            .featured-content{
                background-color: $skin-color;
                color: $white-color;
                h5, a {color: $white-color;}
            }
        }
    }
}

/* post */
.featured-imagebox-post{
    background-color: $white-color;
    padding: 0;
    margin-bottom: 20px;
    transform: translateY(0);
    transition: 0.5s;
    .featured-content{ padding: 22px 27px 22px 27px; }
}
.ttm-box-view-left-image {
    .featured-content {
        .category> a {
            display: inline-block;
            border: 1px solid;
            padding: 5px 10px;
            margin-right: 5px;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            margin-bottom: 5px;
            &:hover{background-color: $skin-color; color:$white-color;border-color: $skin-color}            
        }
        .ttm-box-desc, .featured-desc {
            border-top: 1px solid #f2f2f2;
            margin-top: 7px;
            padding-top: 15px;
        }
        .featured-title a:hover{color:$skin-color;}
    }
    &.featured-imagebox-post{
        padding: 15px;
        .featured-content {
            padding-left: 25px !important;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding-bottom: 0 !important;
        }
    }
    .featured-content {
        padding: 15px 17px 11px 17px;
    }
    .ttm-featured-img-left { padding: 0;}
}

/* ===============================================
    13.List box
------------------------*/
.ttm-list {
    &.ttm-list-style-icon {
        li {
            color: $darkgrey-color;
            padding-left: 27px;
            list-style: none;
            position: relative;
            padding-bottom: 10px;
            i, svg {
                position: absolute;
                left: 0px;
                top: 5px;
            }
        }
    }
}

/* ===============================================
    14.Processbox
------------------------*/
.ttm-processbox-wrapper {
    display: flex;
    position: relative;
    padding-top: 8px;
    .ttm-processbox {
        width: calc(100% / 3);
        padding: 0 15px;
        position: relative;
        text-align: center;
        &:last-child .ttm-box-image:before{content:unset}
        .ttm-box-image {
            border: 3px solid #f0f0f0;
            border-radius: 50%;
            margin-bottom: 35px;
            position: relative;
            display: inline-block;
            transition: all .4s ease-in-out;
            img {
                border: 10px solid $white-color;
                box-shadow: 0 0 9px 0 rgba($darkgrey-color,.09);
                border-radius: 50%;
                transition: all .4s ease-in-out;
                height: 185px;
                width: 185px;
            }
            &::before {
                display: block;
                content: '';
                position: absolute;
                right: -95px;
                top: 45%;
                height: 22px;
                width: 72px;
                background-image: url("../img/process-arrow.png");
                background-repeat: no-repeat;
            }
        }
        .process-num {
            margin-right: 5px;
            font-size: 16px;
            font-weight: 500;
            background-color: $darkgrey-color;
            color: $white-color;
            width: 40px;
            height: 40px;
            line-height: 41px;
            border-radius: 50%;
            border: none;
            display: block;
            text-align: center;
            position: absolute;
            left: 50%;
            margin-left: -20px;
            bottom: -15px;
            z-index: 10;
            transition: all .2s ease-in-out;
        }
    }
    .featured-content {
        text-align: center;
        padding: 0 15px;
    }
    .featured-title h5 {
        margin-bottom: 10px;
    }
    .ttm-processbox:hover {
        .ttm-box-image {border-color: $skin-color;}
        .process-num {background-color: $skin-color;}
    }
}

/* ===============================================
    15. Progress-bar
------------------------*/
.ttm-progress-bar {
    position: relative;
    &:not(:last-child) {
        margin-bottom: 24px;
    }
    .progress-bar-title {
        top: -35px;
        left: 0;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        margin-bottom: 11px;
    }
    .progress {
        height: 6px;
        width: 100%;
        background-color: #e9e9e9;
        overflow: visible; height: auto;
        border-radius: 0;
    }
    .progress-bar {
        border-radius: 0;
        height: 6px;
        position: relative;
        overflow: unset;
        background-color: $skin-color;
        &::before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            bottom: -6px;
            right: -1px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 2px solid $white-color;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .06);
            background-color: $skin-color;
        }
    }    
    .progress-bar-percent {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        text-shadow: none;
    }
}

/* ===============================================
    16. Testimonial
------------------------*/
.testimonials {
    position: relative;
    .testimonial-content {
        .testimonial-avatar {
            display: block;
            margin: 0 auto;
            .testimonial-img img {
                height: 91px;
                width: 91px;
                border-radius: 50%;
                border: 5px solid #f0f0f0;
                margin: 0 auto;
            }
        }
        blockquote {
            padding: 0;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            background-color: transparent;
            line-height: 26px;
            position: relative;
            margin-bottom: 0px;
            margin-top: 20px;
        }
        .ttm-ratting-star {
            padding-top: 12px;
            padding-bottom: 10px;
            color: #f5cc26;
        }
        h6 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0;
        }
        label {
            display: block;
            font-size: 13px;
            margin-bottom: 15px;
            color: #687076;
        }
    }
}
.ttm-bgcolor-darkgrey, .ttm-bgcolor-skincolor{ 
    .testimonial-slide.style1 {
        h6, label {color: rgba($white-color, .8);}
    }
}
.testimonial-slide {
    &.style1 {
        padding: 40px 40px 60px 40px;        
        &.ttm-bgcolor-white {
            h6 {color: $darkgrey-color;}
            label {color: #687076;}
            .slick-dots li {
                background-color: $skin-color;
                &.slick-active {background-color: #e9e9e9;}
            }
        }
    }
}

/* ===============================================
    17.Client-row  
------------------------*/
.client-box{ 
    margin-bottom: 20px;
    padding: 15px 12px 30px;
}
.ttm-client-logo-tooltip{ position: relative; }
.ttm-box-view-separator-logo .client-box .client:after {
    border-right: 1px solid #eaeaea;
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    right: -25px;
    top: 0;
}
.client-box {
    &.ttm-box-view-boxed-logo {
        padding: 0;
        margin-top: 30px; 
        margin-bottom: 30px;
        position: relative;
        display: block;
        .ttm-client-logo-tooltip{
            margin-right: 13px;
            margin-left: 13px;
            padding: 18px 12px;
            display: table-cell;
            text-align: center;
            align-items: center;
            vertical-align: middle;
        }
        .client{
            width: 100%;
            display: table;
            &:hover img {filter: grayscale(1);}
            img {margin: 0 auto;}
        }
    }
}
/* ===============================================
    18. Accordion
------------------------*/
.accordion__button {
    position: relative;
    border: 1px solid #ddd;
    padding: 17px 37px 17px 17px;
    font-size: 17px;
    color: $darkgrey-color;
    &::after {
        position: absolute;
        font-family: "themify";
        right: 20px;
        top: 18px;
        content: "\e61a";
        color: $darkgrey-color;
        font-size: 14px;
        font-weight: bold;
    }
    &:hover {
        cursor: pointer;
        background-color: $skin-color;
        color: $white-color;
        transition: ease-in-out .3s;
        &::after {color: $white-color;}
    }
}
.accordion__panel {
    padding: 14px 20px;
    transition: ease-in-out .3s;
    border: 1px solid #ddd;
    border-top: 0;
}
.accordion__item{margin-bottom: 15px;}

.accordion.style2 .accordion__button {
    padding-bottom: 15px;
    border: 0;
    padding: 0 15px 15px 35px;
    font-weight: 600;
    font-size: 16px;
  }
  
  .accordion.style2 .accordion__button::after {
    left: 0;
    top: 0;
    right: auto;
    content: "\f056";
    font-family: "FontAwesome";
  }
  
  .accordion.style2 .accordion__button:hover {
    background-color: transparent;
    color: #263045;
  }
  
  .accordion.style2 .accordion__button:hover::after {
    color: #263045;
  }
  
  .accordion.style2 .accordion__item {
    border-bottom: 1px solid #ddd;
  }
/* ===============================================
    19.Tab
------------------------*/
.ttm-tabs {
    width:100%; 
    display: flex;
    flex-wrap: wrap;
    .section-title{ padding-bottom: 0; }
    ul.tabs{ 
        margin: 0;
        padding: 0;         
        &.tabs {
            display: flex;
            li { 
                display: inline-block;
                cursor: pointer;
                padding: 8px 22px;
                margin-bottom: 5px;
                line-height: 22px;
                width: auto;
                position: relative;
                z-index: 3;
                transition: all 0.3s;
                font-size: 15px;
                font-weight: 500;
                text-transform: uppercase;
                i{
                    font-size: 18px;
                    margin-right: 9px;
                    position: relative;
                    top: 2px;
                }
                span{
                    // font-size: 18px;
                    font-weight: 600;
                }
                &.react-tabs__tab--selected {
                    background-color: $skin-color;
                    color: $white-color;
                    border-radius: 0;
                }
            }
        }
    }
    .content-tab{ 
        margin-top: 20px;
        width: 100%;
        h6{ font-size: 15px; line-height: 28px; margin-bottom: 29px; padding-bottom: 7px; }
    }
    .content-tab-style4 {
        margin-top: 0;
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
    }
}
.tab-padding-box{
    padding-top: 105px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 70px;
}
/* ttm-tab-style-vertical */
.ttm-tabs.ttm-tab-style-vertical {
    .content-tab{ padding: 0; margin: 0;}
    ul li { 
        padding: 0px;
        width: 100%; 
        display: block;
        text-align: center;
        span{ 
            background-color: #2a2a2a; 
            color: $white-color; 
            letter-spacing: 1.2px; 
            padding: 14px 20px; 
            text-transform: uppercase; 
            font-size: 18px; 
            line-height: 27px; 
            margin: 0; 
            border-radius: 0;
            display: block;
        }
        &.react-tabs__tab--selected {
            border-radius: 0;
            border-color: transparent;
            span{
                background-color: $skin-color;
                color: $white-color;                
            }
        }
    }
}
/* ttm-tab-style-horizontal */
.ttm-tabs.ttm-tab-style-horizontal {
    ul{ 
        margin: 0; padding: 0; display: flex; border: none;
        li{ 
            margin-right: 6px; 
            display: inline-block; 
            padding: 0px;
            &:last-child{ margin-right: 0; }
            span { 
                background-color: #2a2a2a;
                padding: 14px 18px; 
                font-size: 15px; 
                border-radius: 5px; 
                letter-spacing: 1.2px; 
                border: none; 
                color: $white-color; 
                display: block; 
                text-align: left;
                margin-bottom: 5px;
            }
            &.react-tabs__tab--selected {
                border-color: transparent;
                span{
                    background-color: $skin-color;
                    color: $white-color;                
                }
            }
        }
    }
    .content-tab {
        img{ height: 150px; width: 150px; }
    }
}
/* ttm-tab-style-classic-style1*/

.ttm-tabs.ttm-tab-style-classic {
    .content-tab{padding: 30px;margin-top: 35px;}
    &.style1 ul.tabs{
        border: 1px solid #f0f0f0;
        li{
            flex: 1 1 auto;
            text-align: center;
            position: relative;
            color: $darkgrey-color;
            margin-bottom: 0;
            flex-basis: 100%;
            span {
                padding: 7px 0px 15px;
                display: block;
                border: none;
                border-radius: 0;
                margin-bottom: 0;
                &::before{
                    display: block;
                    content: "";
                    position: absolute;
                    bottom: -7px;
                    left: 50%;
                    width: 13px;
                    height: 13px;
                    border-width: 0 0 1px 1px;
                    transform: translateX(-50%) rotate(-45deg);
                    transition-delay: 0.07s;
                }
            }
            &:not(:first-child):before{
                content: "";
                position: absolute;
                left: 0;
                width: 1px;
                height: 76%;
                z-index: 9;
                top: 12%;
                background-color: #f0f0f0;
            }
            &.react-tabs__tab--selected{
                &::before{content: unset;}
                span{
                    color: $white-color;
                    &::before{background-color: $skin-color;}
                    i, svg {color: $white-color; fill: $white-color;}
                }
            }
            &:first-child{margin-left: 0;}
            &:last-child{margin-left: 0;}
            i {
                display: block;
                font-size: 42px;
                width: 100%;
                height: 42px;
                line-height: 60px;
                margin: 0;
                color: $skin-color;
                fill: $skin-color;
                margin-bottom: 10px;
            }
            svg {
                display: block;
                font-size: 42px;
                width: 100%;
                height: 42px;
                margin: 0;
                color: $skin-color;
                fill: $skin-color;
                margin: 15px 0 5px 0;
            }
            img{
                display: block;
                height: 42px;
                width: 42px;
                line-height: 60px;
                margin: 0;
                color: $skin-color;
                margin-left: 50%;
                transform: translate(-50%, 0);
                margin-bottom: 10px;
            }
        }
    }
    &.style2 {
        ul li{
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 0;
            color: $darkgrey-color;
            border: 1px solid;
            font-weight: 600;
            padding: 6px 22px;
            &.react-tabs__tab--selected, &:focus {
                box-shadow: none;
                outline: none;
                background-color: $skin-color;
                color: $white-color;
                border-color: $skin-color;
            }
        }
        .content-tab {
            padding: 0;
            margin: 0;
        }
    }
}

/* ===============================================
    20.Boxes-Spacing
------------------------*/
.row.ttm-boxes-spacing-30px {
    margin: 0 -15px;
    padding: 0;
    .ttm-box-col-wrapper {
        padding-right: 15px;
        padding-left: 15px;
        margin: 0;
    }
    &.style2{ padding: 10px 0 0; margin: 0 -5px; }
}
.row.ttm-boxes-spacing-10px {
    margin: 0 0;
    padding: 0 5px;
    .ttm-box-col-wrapper {
        padding-right: 5px;
        padding-left: 5px;
        margin: 0px 0 10px;
    }

    @media (max-width: 600px) {
        .ttm-box-col-wrapper {
            padding-right: 5px;
            padding-left: 5px;
            margin: 0px 0 30px;
        }
    }

    &.style2{ padding: 10px 0 0; margin: 0 -5px; }
}
.row.ttm-boxes-spacing-5px { 
    padding: 10px 5px 0px; 
    margin: 0;
    .ttm-box-col-wrapper {
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 10px;
    }
    &.style2{ padding: 5px 0 0; margin: 0 -5px; }
}

/* ===============================================
    21.Pricing-Plan
------------------------*/
.ttm-pricing-plan {
    border-radius: 0;
    background-color: $white-color;
    background-clip: padding-box;
    position: relative;
    transition: all 0.3s ease;
    padding: 50px 30px 30px;
    &.ttm-pricing-plan-col{
        z-index: 1;
        padding: 50px 30px 69px;
    }
    .ttm-ptablebox-price {
        padding-left: 20px;
        font-size: 50px;
        line-height: 55px;
        font-weight: 700;
        display: inline-block;
        color: $skin-color;
    }
    .ttm-ptablebox-frequency:before {content: '/ ';}
    .ttm-ptablebox-cur-symbol {
        &-after, &-before {
            font-size: 20px; font-weight: 600; display: inline-block;
        }
        &-before {position: absolute; top: 0;}
    }
    .ttm-ptablebox-frequency {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        color: #6e6e6e;
        font-style: italic;
        display: inline-block;
    }
    .ttm-featured-title {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        width: 100%;
        color: $white-color;
        padding: 12px 15px;
        background-color: $darkgrey-color;
        i { margin-right: 10px; }
    }
}
.ttm-currency-before .ttm-ptablebox-price {
    padding-left: 20px;
    font-size: 50px;
    line-height: 40px;
    font-weight: 600;
}
.ttm-ptablebox-title h3 {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 5px;
}
.ttm-ptablebox-price-w {
    position: relative;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 20px;
}
.ttm-ptablebox-features {
    .ttm-feature-lines {
        list-style-type: none;
        margin: 0;
        padding: 12px 0 20px 0;
        li {
            padding: 0;
            margin: 0;
            font-size: 15px;
            padding-top: 12px;
            padding-bottom: 13px;
            &:not(:last-child){ border-bottom: 1px solid #f1f1f1;;}
        }
    }
}
.price_btn{ 
    display: block; 
    padding-top: 5px; 
    text-align: center;
    .ttm-btn.ttm-btn-size-md{
        font-size: 15px;
    }
}
.ttm-ptablebox-featured-col{
    box-shadow: 0 0 30px rgba(24,35,51,.06);
    padding: 50px 40px 68px;
    z-index: 1;
    background-color: #fff;
    overflow: visible;
}

/* ===============================================
    22.Sidebar
------------------------*/
.sidebar {
    .widget-area {
        .widget {
            position: relative;
            margin: 35px 0 35px;
            padding: 30px;
            box-shadow: 0 0 15px 0 rgba(40,61,88,.07);
            &:first-child{margin-top: 0;}
            a {
                font-weight: 500;
                font-size: 14px;
            }
            .widget-title{ 
                margin-bottom: 15px;
                color: $darkgrey-color;
                &::before{
                    display: block;
                    height: 5px;
                    content: '';
                    background-color: $skin-color;
                    position: absolute;
                    left: 0;
                    width: 10px;
                    top: 0;
                    margin-top: 10px;
                    margin-left: -30px;
                    margin-bottom: 0;
                }
            }
            ul>li:last-child{
                padding-bottom: 0 !important;
            }
        }
    }
    .content-area{
        padding-top: 65px;
        padding-bottom: 55px;
    }
    div.widget-area{
        position: relative;
        margin-top: 65px;
    }
    .sidebar-right, .sidebar-left {
        margin-top: -37px;
        padding-top: 60px;
        padding-bottom: 70px;
        float: right;
    }
    .sep_holder .sep_line{ border-color: #ebebeb; }
}
/*author-widget*/
.ttm-author-widget {
    .author-widget_img img{ 
        margin-bottom: 20px;
    }
    .author-name {
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        display: none;
    }
    .author-widget_text {margin-bottom: 0;}
}

/*widget-search*/
.widget-search{
    padding: 0 !important;box-shadow: unset !important;
    .form-group{
        margin: 0;position: relative;
        input{padding: 14px;padding-bottom: 14px; border: 1px solid transparent;}
        i, svg{
            position: absolute;
            right: 0;
            cursor: pointer;
            font-size: 18px;
            z-index: 1;
            top: 0;
            height: 50px;
            width: 50px;
            text-align: center;
            line-height: 50px;
            background-color: $skin-color;
            border: 1px solid $skin-color;
            color: $white-color;
        }
    }
}

/*widget-categories*/
.sidebar {
    .widget_product_categories, .widget-Categories {
        li span {
            position: absolute;
            right: 0;
            top: 13px;
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            border-radius: 50%;
            display: block;
            font-size: 13px;
            font-weight: 400;
        }
    }
}

/** ttm-recent-post-list **/
ul.ttm-recent-post-list {
    >li {
        padding: 5px 0 15px !important;
        display: inline-block;
        width: 100%;
        line-height: 20px;
        &:last-child{padding-bottom: 0 !important;}
        img {
            display: block;
            width: 70px;
            height: 70px;
            float: left;
            margin-right: 10px;
            padding: 4px;
            border: 1px solid $skin-color;
        }
        .post-date {
            display: block;
            font-size: 11px;
            line-height: 14px;
            margin-top: 5px;
            i{margin-right: 7px;font-size: 11px;}
        }
    }
}
.sidebar {
    ul.ttm-recent-post-list,
    .widget-top-rated-products ul{
        >li{
            padding: 15px 0 !important;
            &:first-child{padding-top: 0 !important;}
            &:last-child{padding-bottom: 0 !important;}
            &:not(:last-child){border-bottom: 1px solid #f0f0f0}
            img {
                padding: 0px !important;
                border: unset!important;
            }
        }
    }
}

/** ttm-recent-post-list **/
ul.ttm-recent-post-list {
    li {
        padding: 5px 0 15px !important;
        display: inline-block;
        width: 100%;
        line-height: 20px;
        &:last-child{padding-bottom: 0 !important;}
        img {
            display: block;
            width: 70px;
            height: 70px;
            float: left;
            margin-right: 10px;
            padding: 4px;
            border: 1px solid #2e384d;
        }
        .post-date {
            display: block;
            font-size: 11px;
            line-height: 14px;
            margin-top: 5px;
            i, svg{margin-right: 7px;font-size: 11px;}
        }
    }
}

/* widget_media_gallery */
.widget.widget_media_gallery .gallery-item{
    display: inline-block;
    max-width: 32%;
    padding: 0 1% 2% 0%;
    text-align: center;
    vertical-align: top;
    width: 100%;
}
/* tagcloud-widget */
.sidebar .widget .tagcloud a {
    display: inline-block;
    padding: 4px 10px;
    margin: 0 5px 10px;
    border: 1px solid #f0f0f0;
    border-radius: 0;
    background-color: transparent;
    &:hover{background-color: $skin-color;color:$white-color;}
}

/* widget-nav-menu */
.widget.widget-nav-menu {
    ul li{ 
        margin-bottom: 2px;
        padding: 0;
        a{
            display: block;
            padding: 13px 15px 13px 70px;
            margin: 5px 0;
            position: relative;
            background-color: transparent;
            border: 1px solid #f0f0f0;
            color: $darkgrey-color;
            z-index: 1;
            font-weight: 500;
            font-size: 15px;
            transition: all 0.3s linear;
            &::before {
                font-family: "Font Awesome 5 Free";
                content: "\f101";
                position: absolute;
                top: 0;
                left: 0;
                border-right: 1px solid #f0f0f0;
                line-height: 50px;
                font-size: 14px;
                height: 100%;
                width: 50px;
                text-align: center;
                background-color: transparent;
                transition: width 0.3s linear;
            } 
            &::after {
                content: "";
                background-color: $skin-color;
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-transition: width 0.3s linear;
                transition: width 0.3s linear;
                z-index: -2;
            } 
            &:hover, &.active {
                color: $white-color;
                &::after {width: 100%;background-color: $skin-color;}     
                &::before {background-color: $darkgrey-color;color: $white-color;}
                  
            }          
        }        
    }
}

/* categories-widget */
.sidebar .widget-categories ul li{
    padding: 12px 0;
    display: block;
    position: relative;
    &:not(:last-child){ border-bottom: 1px solid #f0f0f0;}
    a{
        padding-left: 20px;position: relative; color: $darkgrey-color;
        &:hover{color: $skin-color}
        &::before{
            font-family: "themify";
            content: "\e61a";
            position: absolute;
            top: 4px;
            left: 0;
            line-height: 16PX;
            font-size: 11px;
        }
    }
}

/* product-list-widget */
.sidebar .widget-top-rated-products {
    ul{
        &.product-list-widget > li{
            border-top: 2px solid #f5faff;
            padding-bottom: 15px;
            padding-top: 15px;
            margin: 0;
            list-style: none;
            display: block;
            &:first-child{border-top: none;padding-top: 0;}            
        }
        li {
            a{
                display: block;
                img{
                    width: 80px;
                    float: left;
                    margin-left: 0;
                    margin-right: 15px;
                }
            }
        }
    }
    .star-ratings ul {
        margin-top: -5px;
        li{padding-bottom: 0;}
    }
}
.Price-amount.amount ins {padding: 2px 4px;text-decoration: none;}

/* contact-widget */
.sidebar ul.contact-widget-wrapper li {
    padding-left: 30px;
    position: relative;
    padding-top: 0;
    padding-bottom: 15px;
    i {
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 20px;
    }
}

/* download-widget */
.widget-download ul.download {
    li{
        display: block;
        width: 100%;
        background-color: $darkgrey-color;
        border: none;
        margin-bottom: 15px;
        padding: 0;
        i, svg{
            color: $white-color;
            font-size: 28px;
            padding-right: 20px;
            background: $darkgrey-color;
            width: 63px;
            height: 60px;
            padding: 15px 19px;
            vertical-align: middle;
        }
        a {
            color: $white-color;
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            text-transform: uppercase;
            &:hover{color: $white-color; }
        }
    }
}

/* widget_media_image */
.widget.widget_media_image{padding: 0 !important;}
.banner-img-box {
    padding: 15px;
    color: $white-color;
    text-align: center;
    justify-content: left;
    display: flex;
    align-items: center;
    min-height: 385px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        height: 91%;
        width: 90%;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        border: 1px dashed rgba(255, 255, 255, .2)
    }
    .featured-content{
        padding: 30px;
        i{font-size: 50px;}
        .featured-title h5 {
            font-size: 22px;
            line-height: 28px;
            font-weight: 500;
            margin-bottom: 10px;
            padding-top: 20px;
        }
        .featured-desc p {
            font-size: 14px;
            margin-bottom: 10px
        }
        ul li{
            padding-top: 10px;
            color: rgba($white-color, .8);
            i, svg{
                font-size: 14px;
                margin-right: 7px;
            }
            &:not(:last-child){border-bottom: 1px dashed rgba($white-color, .2);padding-bottom: 10px;}
        }
    }
}

/* pagination */
.ttm-pagination {
    display: block;
     margin-bottom: 20px;
     margin-top: 20px;
     text-align: center;
 }
 .page-numbers {
    width: 40px;
    height: 40px;
    line-height: 39px;
    text-align: center;
    display: inline-block;
    background-color: #f8f9fa;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid #f8f9fa;
    border-radius: 0;
    margin: 0 5px;
    padding: 0;
    transition: all 0.3s ease 0s;
    &.current, &:hover {
        background-color: $skin-color;
        color: $white-color !important;
    }
}

/* ===============================================
    23.Products
------------------------*/
.product{ 
    background-color: $white-color;
    transition: all 0.3s;
    margin-bottom: 35px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 15px 0 rgba(40,61,88,.07);
    .product-content { 
        padding: 18px;
        position: relative; 
        border-top: 1px solid #f0f0f0;
        .product-title h2 { margin: 0; font-size: 17px;line-height: 27px;}
    }
    &:hover {
        .product-thumbnail img {transform: scale(1.1,1.1);}
        .ttm-shop-icon .product-btn{
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
    }
    .onsale {
        background: #51ad00;
        color: $white-color;
        font-size: 10px;
        padding: 0 10px;
        display: table;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        left: 0;
        right: auto;
        min-width: 50px;
        line-height: 26px;
        z-index: 5;
        min-height: auto;
        font-weight: 400;
        margin: 0;
        vertical-align: top;
        border-radius: 0;
    }
}
.product-thumbnail { 
    position: relative; overflow: hidden;
    img {transition: all 500ms;}
}
.ttm-shop-icon { 
    position: absolute;
    bottom: 0px; 
    left: 0px; 
    z-index: 1;
    right: 0;
    text-align: center;
    .product-btn{
        height: 48px;
        line-height: 48px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(20px);
        transition: all 500ms;
        background-color: $darkgrey-color;
        a{color: $white-color;}
    }
}
.product, .widget-top-rated-products {
    .ttm-ratting-star{ font-size: 12px;letter-spacing: 1px;color: #ffdd20;}
    ins{margin-left: 5px;text-decoration: none;}
}
.product-price { 
    font-size: 17px;
    del{font-size: 15px;color: #6e6e6e;}
}
.widget-top-rated-products ins{padding: 1px 2px;}
.products-result-count { float: left;  margin-top: 13px;}
.products-ordering .form-control{padding: 13px 13px;}
.ttm-single-product-info { padding: 30px}
/* single-product-details */
.ttm-single-product-details { 
    background-color: $white-color; 
    margin-bottom: 30px;
    .product-details__short-description { margin-top: 20px; margin-bottom: 50px;}
}
.product {
    &.ttm-single-product-details {
        div{
            &.images { width: 48%; float: left;}
            &.summary { width: 48%; float: right;}
        }
        .product-gallery__image {
            img{ height: auto; max-width: 100%;}
            &:nth-child(n+2) {width: 25%; display: inline-block; margin-top: 4px;}
        }
    }
    .summary .price .Price-amount { font-size: 22px;}
    .content-tab{ 
        width: 100%;
        background: $white-color!important;
        margin-top: -1px;
        padding: 30px;
        border: 1px solid #ececec;
    }
    .Reviews .star-rating { margin: 0; margin-top: 7px;  float: right;}
}
.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    position: absolute!important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}
.summary {
    .ttm-ratting-star { line-height: 1; padding: 0; margin: 0; margin-right: 5px;display: inline-block;}
    form.cart { 
        margin-top: 30px;
        margin-bottom: 30px;
        div.quantity { 
            float: left; 
            margin: 0 4px 0 0;
            .qty { width: 80px; text-align: center; line-height: 1;padding: 10px;}
        }
    }
}
.sku_wrapper,.posted_in { display: block;}
.sku_wrapper span,.posted_in span { font-weight: 600;}
.posted_in a { font-weight: 400; }
.ttm-tabs.tabs-for-single-products{
    padding: 0 30px 30px 30px;
    .content-inner h2 { font-size: 24px;line-height: 1; }
    .react-tabs {
        width: 100%;;
        .react-tabs__tab-list {margin: 0;}
    }
    ul.react-tabs__tab-list li {
        font-weight: 500; 
        padding: 11px 30px;
        font-size: 16px;
        border: 1px solid $darkgrey-color;
        margin: 0 4px;
        position: relative;
        margin-bottom: 0;
        color: $white-color;
        text-transform: capitalize;
        border-radius: 0;
        background-color: $darkgrey-color;
        &:nth-child(1) {
            margin-left: 0;
        }
        &.react-tabs__tab--selected, &:focus {
            background-color: $white-color !important;
            color: $darkgrey-color;
            border-color:#e4e4e4 !important; 
            border-radius: 0;
            box-shadow: none;
            &::before{opacity: 1;}
        }
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: -1px;
            width: 100%;
            height: 3px;
            opacity: 0;
            background-color: $skin-color
        }
    }
}

.related.products { margin-top: 50px; }
#reviews #comments ol{ 
    margin: 0; 
    padding: 0; 
    &.commentlist li {
        padding: 0;
        margin: 0 0 20px;
        position: relative;
        border: 0;
        img.avatar {
            float: left;
            position: absolute;
            top: 0;
            left: 0;
            padding: 3px;
            background: #ebe9eb;
            border: 1px solid #e4e1e3;
            margin: 0;
            box-shadow: none;
            width: 65px;
        }
        .comment-text {
            border: 1px solid #e4e1e3;
            padding: 1em 1em 0;
            border-radius: 0;
            margin-left: 80px;
            p.meta {
                font-size: 12px; 
                margin-bottom: 9px;
                .review__author{font-size: 16px;font-weight: 600;}
            }
        }
    }
}
.comment-form-rating { 
    padding-bottom: 20px;
    .stars{  margin-bottom: 15px; color: #ffdd20; }
}
#review_form_wrapper {
    .comment-reply-title { font-size: 18px; line-height: 20px; color: #283d58;}
    .comment-respond p { margin: 0 0 10px; }
}
#reviews {
    #review_form_wrapper {
        select { width: 154px !important; }
        .comment-respond .form-submit { margin-top: 30px; }
    }
    #comment { height: 75px; }
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    vertical-align: middle;
    width: 100%;
    margin: 0 0 1.75em;
    tr { border: 1px solid #e6e6e6; }
    tbody {
        th { border-right: 1px solid #e6e6e6; }
    }
    &.shop_attributes {
        th {
            width: 150px;
            font-weight: 700;
            padding: 8px;
            border-top: 0;
            border-bottom: 1px dotted rgba(0,0,0,.1);
            margin: 0;
            line-height: 1.5;
        }
        td {
            font-style: italic;
            border-top: 0;
            border-bottom: 1px dotted rgba(0,0,0,.1);
            margin: 0;
            padding-left: 10px;
            line-height: 1.5;
            p { margin: 0; padding: 8px 0;}
        }
    }
}
/* Cart */
.ttm-cart-form, .checkout{ margin: 0; background-color: $white-color;}
.checkout .form-group .form-control{padding: 10px 15px;}
.shop_table{
    border: 1px solid #ededed;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 0px;
    .Price-amount{ color: #4d5257;}
    tr { border: 1px solid #e6e6e6;}
    th {
        font-weight: 700;
        padding: 9px 12px;
        line-height: 1.5em;
        border-top: 1px solid rgba(0,0,0,.1);
    }
    td {
        border-top: 1px solid rgba(0,0,0,.1);
        padding: 5px 10px;
        vertical-align: middle;
        line-height: 1.5em;
    }
    .product-thumbnail img{ width: 32px; height: auto; }
    td.actions{ 
        text-align: right; 
        .form-control { width: 150px;  float: left;padding: 10.5px;}
    }
}
.quantity { height: 41px; width: 88px; }
.coupon { 
    float: left;
    .form-control{  float: left; margin: 0 4px 0 0;}
}
.actions button, .coupon button , a.checkout-button{ border: 0; }
.coupon button , a.checkout-button, button.cart_button{
    padding: 15px 30px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    text-align: center;
}
.cart-collaterals { 
    margin-top: 60px; 
    .cart_totals { float: right; width: 48%;}
}
.cart_totals h2 { margin-bottom: 15px; font-size: 24px;}
a.checkout-button { display: block; }
a.remove {
    display: block;
    font-size: 1.5em;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1;
    border-radius: 100%;
    color: red;
    text-decoration: none;
    font-weight: 700;
    border: 0;
    &:hover { color: #fff; background: red;}
}

.checkout-process-div { margin: 16px 0; overflow: hidden; text-align: center;}
.checkout-button-separator { display: block; opacity: .5; margin: 0 0 16px;}


/* checkout */
.form-row {
    padding: 3px;
    margin: 0 0 6px;
    .required {
        color: red;
        font-weight: 700;
        border: 0!important;
        text-decoration: none;
    }
}
.checkout {
    h3#order_review_heading {
        margin-bottom: 30px;
        margin-top: 15px;
    }
    #payment {
        background: #ebe9eb;
        border-radius: 5px;
    }
}
ul.payment_methods {
    text-align: left;
    padding: 1em;
    border-bottom: 1px solid #d3ced2;
    margin: 0;
    list-style: none outside;
}
#payment{
    .payment_box {
        border-top: 3px solid $skin-color;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 1em;
        border-radius: 2px;
        line-height: 1.5;
        background-color: #f8f9fa;
        color: #515151;
        padding-left: 50px;
    }
    div.form-row { padding: 1em; }
    div.checkout-form {padding: 1em;}
}
.checkout-process-div{
    margin: 16px 0;
    overflow: hidden;
}
.checkout-button-separator{
    display: block;
    opacity: .5;
    margin: 0 0 16px;
}
.coupon_toggle {
    .coupon_code{
        padding: 1em 2em 1em 3.5em;
        margin: 0 0 2em;
        position: relative;
        border-top: 3px solid $skin-color;
        background-color: #f8f9fa;
    }
}
.coupon_toggle .coupon_code, #payment .payment_box {
    &::before {
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 1.5em;
        content: "\e67c";
        font-family: 'themify';
        color: $skin-color;
    }
}
.checkout-form {
    input[type="text"], input[type="tel"], input[type="email"]{line-height: normal;}
    &.checkout-form-row-first{float: left;}
    &.checkout-form-row-last{float: right;}
    .required {
        color: red;
        font-weight: 700;
        border: 0!important;
        text-decoration: none;
    }
}
.checkout {
    .billing-fields, .additional-fields {
        h3 {font-size: 28px;}
    }
    .checkout-form-row-first, .checkout-form-row-last {
        width: 47%;
        overflow: visible;
    }
    .checkout-form-row-first {
        float: left;
    }
    .checkout-form-row-last {
        float: right;
    }    
}

/* ===============================================
    24. History box 
------------------------*/
.ttm-history-box-wrapper {
    position: relative;
    text-align: center;
    margin-top: 5px;
    .ttm-history-box-icon-wrapper {
        position: relative;
        &::before {
            position: absolute;
            content: ' ';
            top: 74px;
            height: 90px;
            width: 1px;
            background-color: #e8eaed;
        }
    }
    .ttm-icon.ttm-icon_element-size-md {
        @include icon-box(70px);
        border: 1px solid #e5e5e5;
        i {font-size:35px;}
    }
    &:hover {
        .ttm-icon{
            transform: scale(1.15);
        }
        .ttm-history-box-details {
            background-color: $skin-color;
            color: $white-color;
        }
        h5, a, svg {
            color: $white-color;
        }
    }
    .ttm-history-box-border {
        position: relative;
        margin: 10px 0 43px;
        border: 1px dashed #e5e5e5;
        width: 111%;
        &::before {
            position: absolute;
            content: ' ';
            left: 50%;
            margin-left: -19px;
            height: 12px;
            width: 12px;
            z-index: 1;
            border-radius: 50px;
            background-color: #e8eaed;
            transition: all .3s ease;
            top: -7px;
        }
    }
    .ttm-history-box-details {
        text-align: center;
        box-shadow: 0 0 12px 0 rgba(32, 46, 60, .06);
        padding: 40px 30px 40px;
        transition: all .4s ease-in-out;
    }
}

/* ===============================================
    25. Slick Slider
------------------------*/
.slick-dots {
    li{
        font-size: 0;
        width: 12px !important;
        height: 10px !important;
        background-color: rgba($white-color, .1);
        transition: all 0.5s ease 0s;
        border: none;
        border-radius: 50%;
        margin: 4px 1px 10px 6px;
        &.slick-active {width: 27px !important; background-color: rgba($white-color, 1);border-radius: 5px;}
        button {
            width: 0;
            height: 0;
            padding: 0;
            &::before {font-size: 0 !important;}
        }
        
    }
}