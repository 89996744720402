@import 'assets/scss/global.scss';


.ttm-topbar-wrapper {
    font-size: 13px;
    height: 52px;
    line-height: 52px;
    .top-contact li {        
        position: relative;

        &:nth-child(1) {
            padding-right: 15px;
            margin-right: 15px;
            &::before {
                content: "";
                height: 18px;
                width: 1px;
                background-color: rgba($white-color,.09);
                display: block;
                position: absolute;
                right: 0;
                top: 17px;
            }
        }

        i {
            padding-right: .5rem;
        }
    }
    .social-icons li {
        text-align: center;
        margin: 0 7px;
    }
    ul li {
        display: inline-block;
    }
    .header-btn a.ttm-btn {
        padding: 14px 25px;
        margin-left: 15px;
        border-color: transparent;
        &:hover {
            background-color: $white-color;
            color: $darkgrey-color;
        }
    }
    .header-btn div.ttm-btn {
        padding: 14px 25px;
        margin-left: 15px;
        border-color: transparent;
        &:hover {
            background-color: $white-color;
            color: $darkgrey-color;
        }
    }
    
}

.menu > ul > li > a {

    text-decoration: none !important;

}

.menu > ul > li > div {

    text-decoration: none !important;
    cursor: pointer;

}

.top-20 {
    top: 20%;
}

.top-25 {
    top: 25%;
}

@media only screen and (max-width: 1199px) {

    .ttm-topbar-wrapper {
        display: none;
    }

    header.ttm-header-style-02 {

        .widget_info,
        .ttm-custombutton {
            display: none !important;
        }
    }

}

@media only screen and (min-width: 1200px) {

    .ttm-header-style-02 .ttm-stickable-header {
        border-top: 1px solid #eaeff5;
    }

    // ttm-header-style-02
    header.ttm-header-style-02 .site-header-menu {
        height: 60px;
        background-color: $grey-color;
    }

    .ttm-header-style-02 {

        .ttm-right-header,
        .menu>ul>li>a {
            height: 60px;
            line-height: 60px;
        }

        .ttm-right-header,
        .menu>ul>li>div {
            height: 60px;
            line-height: 60px;
        }
        
        .site-branding {
            height: 124px;
            line-height: 124px;

            img {
                // height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        li.menu-hasdropdown {
            a {
                border-bottom: 0;
            }

            div {
                border-bottom: 0;
                cursor: pointer;
            }

            &:hover>a::before {
                width: 15px
            }

            &:hover>div::before {
                width: 15px
            }

            &:not(:last-child)>a::after {
                content: "";
                position: absolute;
                height: 20px;
                width: 1px;
                right: 22px;
                top: 50%;
                transform: translateX(0%) translateY(-50%);
                background-color: rgba(38, 48, 69, 0.06);
                display: inline-block;
            }

            &:not(:last-child)>div::after {
                content: "";
                position: absolute;
                height: 20px;
                width: 1px;
                right: 22px;
                top: 50%;
                transform: translateX(0%) translateY(-50%);
                background-color: rgba(38, 48, 69, 0.06);
                display: inline-block;
            }
        }

        .menu>ul>li>a {
            padding: 0 40px 0 0px;

            &::before {
                right: auto;
                bottom: 15px
            }
        }

        .menu>ul>li>div {
            padding: 0 40px 0 0px;

            &::before {
                right: auto;
                bottom: 15px
            }
        }

        .ttm-custombutton {
            position: relative;
            padding-left: 30px;
            font-weight: 500;
            margin-left: 15px;

            &::after {
                content: "";
                width: 5000px;
                height: 100%;
                background-color: $skin-color;
                left: 100%;
                top: 0px;
                position: absolute;
            }
        }

        .ttm-right-header {

            ul,
            .ttm-custombutton {
                display: inline-block;
            }
        }

        .menu {
            justify-content: flex-start;
            height: 60px;
        }

        .widget_info {
            padding-top: 35px;
            padding-bottom: 35px;
        }
    }

    .ttm-header-wrap {
        position: relative;

        .ttm-stickable-header {
            position: absolute;
            z-index: 21;
            width: 100%;
            margin-top: -1px;
        }
    }

    .ttm-stickable-header.fixed-header {
        z-index: 111;
        background-color: #fff;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    }

}

.ttm-search-overlay {
    position: absolute;
    top: 100%;
    right: 30px;
    width: 400px;
    transform: scale(1);
    background: $white-color;
    border-top: 3px solid $skin-color;
    transition: all ease .3s;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
    z-index: 111;
    .w-search-form-row {
        display: table;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        position: relative;
        padding: 20px;
        &::before {
            position: absolute;
            right: 17px;
            top: -8px;
            width: 47px;
            height: 47px;
            text-align: center;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid $grey-color;
            content: " ";
        }
    }
    .ttm-site-searchform {
        input[type="search"] {
            font-size: 14px;
            text-align: left;
            height: 45px;
            border: none;
            border-radius: 0;
            box-shadow: none!important;
            color: #333;
            width: 88%;
            line-height: 45px;
            float: left;
            padding: 9px 15px;
            background-color: $grey-color;
        }
        button {
            font-size: 14px;
            width: 12%;
            padding: 0;
            border: 0;
            border-radius: 0;
            height: 45px;
            text-align: center;
            display: block;
            line-height: 50px;
            color: #fff;
            float: left;
            background-color: $skin-color;
        }
    }
}

.ttm-language-overlay {
    position: absolute;
    top: 45px;
    right: -15px;
    width: 60px;
    transform: scale(1);
    background: $white-color;
    border-top: 3px solid $skin-color;
    transition: all ease .3s;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
    z-index: 111;
    
    ul {
        li {
            color: $body-font-color;
            font-size: 15px;
            line-height: 1;
            padding: 15px;
            font-weight: 600;

            width: unset;
            height: unset;
        }
        li:hover {
            color: $skin-color;
            background-color: $grey-color;
        }
        &::before {
            position: absolute;
            right: 20px;
            top: -8px;
            width: 47px;
            height: 47px;
            text-align: center;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid $grey-color;
            content: " ";
        }
    }
}