.mobile-menu-button {
    
    width: 80px;
    height: 80px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    svg {

        width: 32px;
        height: 32px;

    }

}

.mobile-menu-parent-border {

    border-radius: unset;
    border-bottom: 1px solid rgba(93, 101, 118, 0.15);

}

.mobile-menu-child-border {

    border-radius: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);

}