// @tailwind base;
@tailwind components;
@tailwind utilities;

// Load Open Sans and satisfy typeface font
@import '@fontsource/comfortaa/300.css';
@import '@fontsource/comfortaa/400.css';
@import '@fontsource/comfortaa/500.css';
@import '@fontsource/comfortaa/600.css';
@import '@fontsource/comfortaa/700.css';

@import 'assets/scss/global.scss';
@import 'assets/scss/shortcode.scss';
@import 'assets/scss/main.scss';
@import 'assets/scss/responsive.scss';

// FlatIcons
@import 'assets/scss/flaticon.scss';

// FontAwesome
@import 'assets/scss/fontawesome/fontawesome.scss';
@import 'assets/scss/fontawesome/brands.scss';
@import 'assets/scss/fontawesome/regular.scss';
@import 'assets/scss/fontawesome/solid.scss';

// animate.css
@import 'animate.css';

// slick carousel
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import 'assets/scss/slick.scss';

// themify-icons
@import "assets/css/themify-icons.css";

// ckeditor
@import "assets/css/ckeditor.css";

// react-tabs
@import 'react-tabs/style/react-tabs.css';

// external
@import "react-toastify/dist/ReactToastify.css";
